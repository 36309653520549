<script>
import Vue from 'vue'
import { logger } from '@sigmacloud/sigma-client/dist/logger'

export default Vue.extend({
    components: {},
    props: {
        instance: {
            type: String,
            required: true,
        },
        instanceName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            username: '',
            password: '',
            showInstanceError: false,
            instanceErrorText: '',
            showLoginError: false,
            loginErrorText: '',
        }
    },
    beforeMount() {
        if (!this.$sigma.validHostOrInstanceName(this.instanceName)) {
            this.instanceErrorText = `${this.instanceName} may not be a valid instance name.`
            this.showInstanceError = true
        }
    },
    methods: {
        async onSubmit() {
            try {
                this.showLoginError = false
                await this.$sigma.login(this.instance, this.username, this.password)
                // Build the href of the redirect
                let redirectTo = this.$router.resolve({ name: 'appHome', params: { instanceName: this.instanceName } })
                // If we want to go somewhere else instead, ?next= is provided
                if (this.$route.query.next) {
                    redirectTo = this.$router.resolve(this.$route.query.next)
                }
                // Actually reload the window when initially logging into an instance
                window.location.href = redirectTo.href
            } catch (e) {
                if (e.response) {
                    logger.warn('login error')

                    if (e.response.status === 400) {
                        this.loginErrorText = e.response.data.non_field_errors[0]
                    }
                    if (e.response.status === 500) {
                        this.loginErrorText = 'Server Error. Try again later.'
                    }
                } else if (e.request) {
                    logger.warn('login failed: no response from server')
                    this.loginErrorText = 'There is no response from this instance. Please check your internet connection or that the instance name is correct.'
                } else {
                    logger.error('Unknown login error')
                    throw e
                }

                this.showLoginError = true
            }
        },
    },
})
</script>

<template>
    <div v-page-title="'Login'" class="login">
        <b-container>
            <b-row>
                <b-col md="4" offset-md="4">
                    <form @submit.prevent="onSubmit">
                        <b-card :header="`Log in to ${instanceName}`" class="mt-5">
                            <b-alert v-model="showInstanceError" variant="warning">{{ instanceErrorText }}</b-alert>
                            <b-form-group label="Email or Username">
                                <b-form-input v-model="username" required />
                            </b-form-group>
                            <b-form-group label="Password">
                                <b-form-input v-model="password" type="password" required />
                            </b-form-group>
                            <template #footer>
                                <b-button type="submit">Submit</b-button>
                            </template>
                            <b-alert v-model="showLoginError" variant="danger">{{ loginErrorText }}</b-alert>
                        </b-card>
                    </form>
                </b-col>
            </b-row>
        </b-container>
    </div>
</template>
